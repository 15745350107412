import React from "react";

const Colonoscopy = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Colonoscopy Q & A
        </p>

        <p className="text-md text-gray-600 mb-2">
          Colorectal cancer screening helps people stay well and saves lives.
          Regular colorectal cancer testing is one of the most powerful weapons
          for preventing colorectal cancer. Removing polyps prevents colorectal
          cancer from ever starting. And cancers found in an early stage, while
          they are small and before they have spread, are more easily treated.
          Nine out of 10 people whose colon cancer is discovered early will be
          alive 5 years later. And many will live a normal life span.
        </p>
        <p className="text-md text-gray-600 mb-2">
          But too often people don’t get these tests. Then the cancer can grow
          and spread without being noticed, like a silent invader.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Early stage colorectal cancer does not usually have symptoms. In most
          cases, by the time people do have symptoms the cancer is very advanced
          and very hard to treat. Regular screening is necessary to find these
          cancers in the early stages. Ask a doctor about the best screening
          plan for you.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Colonoscopy (ko-lun-AH-skuh-pee) is an exam that lets a doctor closely
          look at the inside of the entire colon. The doctor is looking for
          polyps (pah-lips) or signs of cancer. Polyps are small growths that
          over time can become cancer. The doctor uses a thin (about the
          thickness of a finger), flexible, hollow, lighted tube that has a tiny
          video camera on the end. This tube is called a colonoscope
          (ko-LAHN-uh-scope). The colonoscope is gently eased inside the colon
          and sends pictures to a TV screen. Small amounts of air are puffed
          into the colon to keep it open and let the doctor see clearly.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The exam itself takes about 30 minutes. Patients are usually given
          medicine to help them relax and sleep while it’s done.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Your doctor decides how often you need this test, usually once every
          10 years, depending on your personal risk for colon cancer. It’s
          important for you to talk with your doctor to understand your risk for
          colon cancer, the guidelines you should follow for testing, and
          whether you need to start having the tests at age 50 or earlier.
        </p>

        <p className="text-xl text-gray-800 mb-2">Will it hurt?</p>
        <p className="text-md text-gray-600 mb-12">
          No, these exams are not painful. In most cases, patients are given
          medicine to sleep through the colonoscopy, so they don’t feel
          anything. Sigmoidoscopy doesn’t require medicine to make the patient
          sleepy. Air is pumped into the cleaned-out colon to keep it open so
          that doctors can get the best pictures. While the air pressure may
          cause some discomfort, it should not hurt.
        </p>

        <p className="text-xl text-gray-800 mb-2">Who will do the exam?</p>
        <p className="text-md text-gray-600 mb-12">
          Colonoscopy is almost always done by a doctor, usually a
          gastroenterologist (a doctor whose specialty is the digestive tract)
          or a surgeon.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Will I be in a private room?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Colonoscopy is done in a private area; it may be a hospital outpatient
          department, a clinic, an ambulatory surgery center, or a doctor’s
          office. The patient’s privacy is very important. If you have concerns,
          talk with the doctor about where and how the procedure will be done.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Sigmoidoscopy typically is done in a private room, with no other
          patients nearby. Doctors and nurse specialists are professional and
          very careful to respect the patient’s privacy.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How do I prepare? Will I need to miss work?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Preparation for colonoscopy or sigmoidoscopy makes you to go the
          bathroom a lot! Your doctor will give you instructions. Read them
          carefully a few days ahead of time, since you may need to shop for
          special supplies and get laxatives or enemas from a pharmacy. You may
          also need to change your plans for the preparation day. You will need
          to be near your bathroom as soon as you start the laxatives. If any of
          the instructions are not clear or you do not understand them, call the
          doctor’s office and go over them step by step with the nurse.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Many people consider the bowel preparation (often called the bowel
          prep) the worst part of the test. You follow a special diet for one or
          more days before the exam and take very strong laxatives before the
          procedure. You may also need enemas to clean out your colon. In order
          for the doctor to see the inside of your colon clearly and get good
          pictures, it should be as cleaned out as possible.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Because colonoscopy is usually done with drugs that make you sleepy,
          most people miss a day of work. Ask your doctor if you’ll need to miss
          work before a sigmoidoscopy. For either test you’ll need to stay close
          to a bathroom for a number of hours. You might want to schedule the
          test the day after a regularly scheduled day off, so you can be at
          home the day before without taking an extra day off.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How will I feel afterward? Will I need someone to drive me home?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Most people feel OK after a colonoscopy. They may feel a bit woozy
          from the drugs (anesthesia). They’ll be watched and given fluids as
          they wake up. They may have some gas, which could cause mild
          discomfort. Because of the medicines given for the test, most
          facilities require that you bring someone to take you home.
        </p>
        <p className="text-md text-gray-600 mb-12">
          After a sigmoidoscopy, you get up and walk out. There should be no
          problem driving yourself home, as long as you have not had any drugs
          to make you sleepy during the test.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What if they find something?
        </p>
        <p className="text-md text-gray-600 mb-12">
          If a small polyp is found, your doctor will probably remove it. Over
          time some polyps could become cancer. If your doctor sees a large
          polyp, a tumor, or anything else abnormal, a biopsy (by-op-see) will
          be done. For the biopsy, a small piece of tissue is taken out through
          the colonoscope or sigmoidoscope. It is sent to a lab to be checked
          under a microscope for cancer or pre-cancer cells.
        </p>
      </div>
    </div>
  );
};

export default Colonoscopy;
