import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import Conditions from "../../../components/Conditions";
import Colonoscopy from "../../../components/resources/Colonoscopy";

const WhyDoINeedAColonoscopy = () => {
  return (
    <Layout>
      <PageHeader text="Why Do I Need A Colonoscopy?" />
      <Colonoscopy />
      <Conditions />
    </Layout>
  );
};

export default WhyDoINeedAColonoscopy;
